import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useLocation, useHistory } from 'react-router-dom'

import { Link } from 'components'
import { matchUrl } from 'helpers'

import Game from '../Game'

import './Games.scss'
import { ErrorBoundary } from 'components'

const Games = ({ match, title, setCurrentGameResult }) => {
  const [gameNumber, setGameNumber] = useState(null)
  const [inProgressGameNumber, setInProgressGameNumber] = useState(
    () => match.games.find((g) => g.state === 'inProgress')?.number
  )
  const { hash, pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    const gamesCount = match.games.length
    let hashGameNumber = Number((hash || '').replace('#game-', ''))

    if (hashGameNumber) {
      if (hashGameNumber > gamesCount) {
        hashGameNumber = null
      } else if (match.games.find((g) => g.number === hashGameNumber).state === 'unneeded') {
        hashGameNumber = null
      }
    }

    if (!hashGameNumber) {
      if (gamesCount > 1) {
        const inProgressGame = match.games.find((g) => g.state === 'inProgress')
        const unstartedGame = match.games.find((g) => g.state === 'unstarted')

        const gameNumber = inProgressGame
          ? inProgressGame.number
          : unstartedGame
          ? unstartedGame.number
          : 1

        history.replace(`${pathname}#game-${gameNumber}`)
        setGameNumber(gameNumber)
      } else {
        history.replace(`${pathname}#game-1`)
        setGameNumber(1)
      }
    } else {
      setGameNumber(hashGameNumber)
    }
  }, [match, history, pathname, hash])

  // Go to next game
  useEffect(() => {
    const inProgressGame = match.games.find((g) => g.state === 'inProgress')
    if (inProgressGame && inProgressGameNumber !== inProgressGame.number) {
      setInProgressGameNumber(inProgressGame.number)
      history.replace(`${pathname}#game-${inProgressGame.number}`)
      setGameNumber(inProgressGame.number)
    }
  }, [match, history, pathname, inProgressGameNumber])

  if (!gameNumber) return null

  const game = match.games.find((g) => g.number === gameNumber)

  return (
    <div className="games">
      <div className="tabs">
        {match.games.length > 1 && (
          <div className="tabs-wrapper">
            {match.games
              .filter((game) => game.state !== 'unneeded')
              .map((game) => (
                <Link
                  key={game.id}
                  to={`${matchUrl(match)}#game-${game.number}`}
                  className={cn({ active: game.number === gameNumber })}
                  disabled={game.state === 'unstarted'}
                >
                  <div className="tab">
                    <span className="prefix">Game</span>
                    <span>{game.number}</span>
                  </div>
                </Link>
              ))}
          </div>
        )}
      </div>
      <div className="game-wrapper" id={gameNumber ? `#game-${gameNumber}` : null}>
        <ErrorBoundary>
          {game.id && (
            <Game
              key={game.id}
              match={match}
              game={game}
              title={title}
              setCurrentGameResult={setCurrentGameResult}
            />
          )}
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default Games

import React, { useEffect, useMemo } from 'react';

import { useGameData } from 'services';
import { ErrorBoundary, Loading } from 'components';

import { GameContext } from './GameContext';

import Events from './Events';
import Frame from './Frame';
import NoData from './NoData';

import './Game.scss';
import { DataDragonProvider } from 'services/ddragon';
import { RoadToWorldsProvider } from 'services/roadToWorlds';

const findTeamColors = (match, data) => {
  if (!data?.metadata) return [];

  const firstPlayer = data?.metadata.participants[0];

  if (firstPlayer.name.startsWith(match.teams[0].code)) {
    return match.teams;
  } else if (firstPlayer.name.startsWith(match.teams[1].code)) {
    return [match.teams[1], match.teams[0]];
  } else {
    // If we can't find the team based on the first player's name, we just return the original order
    return match.teams;
  }
};

const Game = ({ match, game, title, setCurrentGameResult }) => {
  const { loading, data, error } = useGameData(game);

  const contextVal = useMemo(() => {
    if (!data) return null;

    const { frame, metadata, events, goldDiff } = data;

    const [blueTeam, redTeam] = findTeamColors(match, data);

    return { match, game, blueTeam, redTeam, frame, events, metadata, goldDiff };
  }, [data, game, match]);

  const gameState = data?.frame?.state;

  useEffect(() => {
    // Can't use Helmet because Helmet doesn't update when window is out of focus
    document.title = `${
      gameState === 'in_game' ? '🔴 ' : gameState === 'paused' ? '⏸ ' : ''
    }${title}`;

    return () => (document.title = 'The Piltover Post');
  }, [gameState, title]);

  useEffect(() => {
    const winnerEvt = (data?.events || []).find((evt) => evt.type === 'winner');
    if (winnerEvt) {
      setCurrentGameResult({ code: winnerEvt.winner, number: game.number });
    }
  }, [data, setCurrentGameResult, game.number]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return 'An unknown error appeared. Please try reloading the page.';
  }

  if (!data || (data.frame === null && data.metadata === null && data.events.length === 0)) {
    return <NoData match={match} game={game} />;
  }

  return (
    <div id="game">
      <DataDragonProvider metadata={data.metadata}>
        <RoadToWorldsProvider>
          <GameContext.Provider value={contextVal}>
            <ErrorBoundary>
              <Frame />
            </ErrorBoundary>
            <ErrorBoundary>
              <Events />
            </ErrorBoundary>
          </GameContext.Provider>
        </RoadToWorldsProvider>
      </DataDragonProvider>
    </div>
  );
};

export default Game;

/* Based on the Early-Game Win Probability Calculator by Oracle's Elixir */

const data = {
  split: 'Summer2022',
  gameCount: 1416,
  updatedAt: '2023-01-16T13:04:03.000Z',
  goldDiff: 0.000580662,
  infernalDiff: 0.512633,
  mountainDiff: 0.44538,
  oceanDiff: 0.358443,
  cloudDiff: 0.362758,
  chemtechDiff: 0,
  hextechDiff: 0.165581,
  side: -0.245741,
}

export const calculateOdds = (blueTeam, redTeam) => {
  const side = -1 // We calculate for blue side
  const goldDiff = blueTeam.totalGold - redTeam.totalGold
  const [infernalDiff, mountainDiff, oceanDiff, cloudDiff, chemtechDiff, hextechDiff] = [
    'infernal',
    'mountain',
    'ocean',
    'cloud',
    'chemtech',
    'hextech',
  ].map(
    (type) =>
      blueTeam.dragons.filter((d) => d === type).length -
      redTeam.dragons.filter((d) => d === type).length
  )

  const logOdds =
    side * data.side +
    goldDiff * data.goldDiff +
    infernalDiff * data.infernalDiff +
    mountainDiff * data.mountainDiff +
    oceanDiff * data.oceanDiff +
    cloudDiff * data.cloudDiff +
    chemtechDiff * data.chemtechDiff +
    hextechDiff * data.hextechDiff

  const odds = 2.71828182845904523536 ** logOdds
  const winProbability = (odds / (1 + odds)) * 100

  return winProbability
}

import React from 'react'
import cn from 'classnames'

import { eventColor } from 'helpers'
import { Gold } from 'icons'

const GoldThreshold = ({ event, blueTeam, redTeam, metadata }) => {
  const improvingTeam = event.to > event.from ? blueTeam : redTeam
  const recedingTeam = event.to > event.from ? redTeam : blueTeam
  let sentence

  if (event.from === 0) {
    sentence = (
      <>
        {improvingTeam.name} takes a <b>4K</b> gold lead on their opponents
      </>
    )
  } else if (Math.abs(event.from) === 4000) {
    if (event.to === 0) {
      sentence = (
        <>
          {improvingTeam.name} reduced the gold difference and is now <b>even</b> with{' '}
          {recedingTeam.name}
        </>
      )
    } else {
      sentence = (
        <>
          {improvingTeam.name} extended their gold lead to <b>8K</b>
        </>
      )
    }
  } else if (Math.abs(event.from) >= 8000) {
    if (Math.abs(event.to) < Math.abs(event.from)) {
      sentence = (
        <>
          {improvingTeam.name} reduced the gold difference, but is still{' '}
          <b>{Math.floor(Math.abs(event.to) / 1000)}K</b> gold behind {recedingTeam.name}
        </>
      )
    } else {
      sentence = (
        <>
          {improvingTeam.name} now has a <b>{Math.floor(Math.abs(event.to) / 1000)}K</b> gold lead!
        </>
      )
    }
  }

  return (
    <div
      className={cn('event', 'gold-threshold', eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <Gold />
        <small className="subtitle">{sentence}</small>
      </div>
    </div>
  )
}

export default GoldThreshold

import React, { useState } from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet-async'
import formatRelative from 'date-fns/formatRelative'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import differenceInDays from 'date-fns/differenceInDays'

import { useEndpoint } from 'services'
import { ErrorBoundary, Loading, Link, Image } from 'components'

import Games from './Games'

import './Match.scss'
import { TV } from 'icons'
import { format } from 'date-fns'

const Match = ({
  match: {
    params: { match_slug },
  },
}) => {
  const match_id = match_slug.split('-').pop()

  const { loading, data } = useEndpoint('match?id=' + match_id, true)
  const { data: scheduleData } = useEndpoint(
    data ? 'schedule?leagueId=' + data.event.league.id : null,
    true
  )

  const [currentGameResult, setCurrentGameResult] = useState(null)

  if (loading || !scheduleData) {
    return <Loading />
  }

  const event = data?.event

  if (!event) {
    return 'Match not found'
  }

  const { match, league } = event

  const {
    blockName,
    state,
    startTime,
    match: { teams: scheduleTeams },
  } = (scheduleData?.schedule.events || []).find((e) => e.match?.id === event.id) || { match: {} }

  const winner = scheduleTeams?.find((t) => t.result?.outcome === 'win')

  const watchUrl =
    state === 'completed'
      ? `https://lolesports.com/vod/${event.id}/1`
      : 'https://lolesports.com/live/' + league.slug

  const sortedTeams = [match.teams[0].code, match.teams[1].code].sort()

  const title = `${match.teams[0].code} vs ${match.teams[1].code} - ${league.name} - ${blockName} - The Piltover Post`

  let score
  if (state !== 'unstarted') {
    score = match.teams.map((team) => team.result.gameWins)

    if (currentGameResult?.number > score[0] + score[1]) {
      if (match.teams[0].code === currentGameResult.code) {
        score[0]++
      } else {
        score[1]++
      }
    }
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://piltoverpost.gg/match/${sortedTeams[0]}-vs-${sortedTeams[1]}-${match_id}`}
        />
      </Helmet>
      <main id="match">
        <div className="meta">
          <div className="meta-header">
            <div className="meta-title">
              {league.name} - {blockName}
            </div>
            <div className="stream">
              {state && watchUrl && (
                <Link
                  to={watchUrl}
                  external
                  data-tooltip={state === 'completed' ? 'VOD' : 'Live stream'}
                  data-tooltip-direction="left"
                >
                  <TV />
                </Link>
              )}
            </div>
          </div>
          <div className="teams-wrapper">
            <div className={cn('teams', { 'has-winner': winner })}>
              <Image
                className={cn('image left', { winner: winner?.code === match.teams[0].code })}
                src={`/images/teams/${match.teams[0].code}`}
                alt=""
              />
              <span className="vs">
                {state && (state === 'unstarted' ? 'vs' : `${score[0]} - ${score[1]}`)}
              </span>
              <Image
                className={cn('image right', { winner: winner?.code === match.teams[1].code })}
                src={`/images/teams/${match.teams[1].code}`}
                alt=""
              />
              <span className={cn('name left', { winner: winner?.code === match.teams[0].code })}>
                {match.teams[0].name}
              </span>
              <span className="state">
                {state &&
                  (state === 'inProgress' ? (
                    <>
                      <span role="img" aria-label="">
                        🔴
                      </span>{' '}
                      LIVE
                    </>
                  ) : state === 'unstarted' ? (
                    <i>
                      {differenceInDays(new Date(startTime), new Date()) <= 6
                        ? formatRelative(new Date(startTime), new Date())
                        : format(new Date(startTime), 'dd/MM/yyyy')}
                    </i>
                  ) : (
                    <i>{formatDistanceToNow(new Date(startTime), { addSuffix: true })}</i>
                  ))}
              </span>
              <span className={cn('name right', { winner: winner?.code === match.teams[1].code })}>
                {match.teams[1].name}
              </span>
            </div>
          </div>
        </div>
        <ErrorBoundary>
          <Games
            match={{ ...match, id: match_id, league, startTime, state }}
            title={title}
            setCurrentGameResult={setCurrentGameResult}
          />
        </ErrorBoundary>
      </main>
    </>
  )
}

export default Match

import React from 'react'
import cn from 'classnames'

import { eventColor, formatGold } from 'helpers'
import { Gold } from 'icons'

const Powerplay = ({ event, blueTeam, redTeam, metadata }) => {
  const { name } = { blueTeam, redTeam }[event.team + 'Team']

  const type = event.type.startsWith('baron') ? 'Baron' : 'Elder'

  return (
    <div
      className={cn('event', 'gold-threshold', eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <Gold />
        <small className="subtitle">
          {name}'s {type} Powerplay: <b>{formatGold(event.diff, false)}</b> gold
        </small>
      </div>
    </div>
  )
}

export default Powerplay

import { useEffect, useState } from 'react'

export const useLocalStorageState = (key, defaultState, json = false) => {
  const [state, setState] = useState(() => {
    const localStorageValue = localStorage.getItem(key)

    if (localStorageValue === null) {
      return defaultState
    }

    if (localStorageValue === 'null') {
      return null
    }

    if (json) {
      return JSON.parse(localStorageValue)
    }

    return localStorageValue
  })

  useEffect(() => {
    localStorage.setItem(key, json ? JSON.stringify(state) : state)
  }, [key, state, json])

  return [state, setState]
}

import React, { useEffect, useState } from 'react'

import './Loading.scss'

const Loading = ({ text, delay = 300 }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handle = setTimeout(() => {
      setShow(true)
    }, delay)
    return () => clearTimeout(handle)
  }, [delay])

  return (
    <div
      style={{
        marginTop: '5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80px',
      }}
    >
      {show && (
        <>
          <div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube"></div>
            <div className="sk-cube2 sk-cube"></div>
            <div className="sk-cube4 sk-cube"></div>
            <div className="sk-cube3 sk-cube"></div>
          </div>
          {text && <p>{text}</p>}
        </>
      )}
    </div>
  )
}

export default Loading

export const goldRatio = (frame, team) => {
  const totalGold = frame.blueTeam.totalGold + frame.redTeam.totalGold || 1
  return frame[`${team}Team`].totalGold / totalGold
}

export const formatGold = (gold, space = true, upper = true) =>
  Math.abs(gold) <= 1
    ? 0
    : Math.abs(gold) > 1000
    ? `${Math.round(gold / 100) / 10}${space ? ' ' : ''}${upper ? 'K' : 'k'}`
    : gold

export const formatTime = (seconds) =>
  `${Math.floor(seconds / 60)}:${('' + (seconds % 60)).padStart(2, '0')}`

export const timestampToSeconds = (ts) =>
  ts
    ?.split(':')
    .map(Number)
    .reduce((min, sec) => min * 60 + sec)

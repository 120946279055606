import { useState, useEffect } from 'react'

const cache = {}

export const useEndpoint = (endpoint, withInterval) => {
  const [data, setData] = useState({ loading: !cache[endpoint], data: cache[endpoint] })

  useEffect(() => {
    setData({ loading: !cache[endpoint], data: cache[endpoint] })

    if (endpoint) {
      fetch(process.env.REACT_APP_API_URL + endpoint)
        .then((r) => r.json())
        .then(({ data }) => {
          cache[endpoint] = data
          setData({ loading: false, data })
        })

      if (withInterval) {
        const interval = setInterval(() => {
          fetch(process.env.REACT_APP_API_URL + endpoint)
            .then((r) => r.json())
            .then(({ data }) => {
              cache[endpoint] = data
              setData({ loading: false, data })
            })
        }, 2 * 60 * 1000)
        return () => clearInterval(interval)
      }
    }
  }, [endpoint, withInterval])

  return data
}

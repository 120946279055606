import React from 'react'
import cn from 'classnames'

import { eventColor, nth } from 'helpers'
import { Tower as Icon } from 'icons'

const Tower = ({ event, blueTeam, redTeam, metadata }) => {
  const { name } = { blueTeam, redTeam }[event.team + 'Team']

  return (
    <div
      className={cn('event', 'tower', eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <Icon />
        <small className="subtitle">
          {name} take their <b>{nth(event.count)}</b> tower
        </small>
      </div>
    </div>
  )
}

export default Tower

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ErrorBoundary, FilterProvider } from 'components'
import { useTracking } from 'helpers'

import { Header, Footer } from './components'

import Home from '../Home'
import Match from '../Match'
import News from '../News'
import Faq from '../Faq'
import Pages from '../Pages'

import 'styles/app.scss'

const App = () => {
  useTracking()

  return (
    <FilterProvider>
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <ErrorBoundary>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/match/:match_slug/:game_id?" component={Match} />
          <Route path="/news/:league?" component={News} />
          <Route path="/faq" component={Faq} />
          <Route path="/pages" component={Pages} />
          <Route>404</Route>
        </Switch>
      </ErrorBoundary>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
    </FilterProvider>
  )
}

export default App

import config from 'config.js'

import { useFilter } from 'components'

export const leagueIds = Object.values(config.default.leagues)
  .reduce((acc, category) => [...acc, ...Object.values(category).filter((l) => !l.disabled)], [])
  .map((l) => l.lolesportsId)

export const useFilteredLeagueIds = () => {
  const { leagues } = useFilter()

  return Object.values(config.default.leagues)
    .reduce((acc, category) => [...acc, ...Object.values(category)], [])
    .filter((l) => leagues[l.code])
    .map((l) => l.lolesportsId)
}

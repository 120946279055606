import React from 'react'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import { Link } from 'components'

const NoData = ({ match, game }) => {
  if (match.state === 'unstarted') {
    return (
      <div className="no-data">
        <h3>This match hasn't started yet!</h3>
        <div className="divider" />
        <p>
          The official starting time of the match is{' '}
          <b>
            {match?.startTime && format(new Date(match?.startTime), 'iii, d MMM yyyy - HH:mm O')}
          </b>
          , but it can be slightly delayed by previous matches.
        </p>
        <p>
          <b>How to catch the match:</b>
        </p>
        <ul style={{ width: '100%' }}>
          <li>
            Watch live on{' '}
            <Link to={'https://lolesports.com/live/' + match.league.slug} external>
              LoL Esports
            </Link>
          </li>
          <li>
            Watch the vod later on{' '}
            <Link to={`https://lolesports.com/vod/${match.id}/${game.number}`} external>
              LoL Esports
            </Link>
          </li>
          <li>
            Can't watch, but still want to follow the game?
            <br />
            <b>Then stay right here on The Piltover Post, and read what happens live!</b>
          </li>
        </ul>
        <div className="divider" />
        <p>
          While you are here, have a look at a selection of high value matches on The Piltover Post:
        </p>
        <ul>
          <li>
            Words 2021 Final: <Link to="/match/DK-vs-EDG-106926282335055885">DK vs EDG</Link>
          </li>
          <li>
            Worlds 2020 Final: <Link to="/match/DWG-vs-SN-104841804589544599">DWG vs SN</Link>
          </li>
          <li>
            EU Masters 2020 Final: <Link to="/match/GL-vs-RGO-104727755403949152">GL vs RGO</Link>
          </li>
        </ul>
      </div>
    )
  }

  if (match.state === 'inProgress') {
    if (game.state === 'unstarted' && game.number > 1) {
      return (
        <div className="no-data">
          <h3>This game will start after the previous game ends</h3>
          <p>No need to refresh</p>
        </div>
      )
    }

    if (game.state === 'inProgress' || game.number === 1) {
      return (
        <div className="no-data">
          <h3>This game will start soon</h3>
          <p>No need to refresh</p>
        </div>
      )
    }
  }

  if (isBefore(parseISO(match.startTime), parseISO('2020-09-23'))) {
    return (
      <div className="no-data">
        <h3>No data available</h3>
        <p>
          Unfortunately, this match happened before the launch of The Piltover Post. If this match
          is important to you, contact us on Twitter (<Link twitter />) and we will look into it.
        </p>
      </div>
    )
  }

  return (
    <div className="no-data">
      <h3>No data available</h3>
      {match.league.slug === 'all-star' ? (
        <p>
          Unfortunately, Riot used different-than-usual server setup during the AllStar Event. This
          means that we weren't able to retrieve the needed data to provide you with a live ticker.
          <span role="img" aria-label="sad emoji">
            😔
          </span>
        </p>
      ) : (
        <p>
          This is likely a bug. Please let us know about the issue here: <Link twitter />
        </p>
      )}
    </div>
  )
}

export default NoData

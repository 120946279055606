import React from 'react'

const Image = ({ src, ...props }) => {
  return (
    <picture>
      <source srcSet={`${src}.webp`} type="image/webp" />
      <img src={`${src}.png`} alt="" {...props} />
    </picture>
  )
}

export default Image

import React, { useCallback } from 'react'
import cn from 'classnames'

import { goldRatio, formatGold, useLocalStorageState } from 'helpers'
import { Kill } from 'icons'
import { GoldGraph } from 'components'

import { useGameData } from '../GameContext'

import { GameTimer, BaronTimer, DragonTimer, Dragons, TeamStats, TeamPlayers } from './components'

import './Frame.scss'

const Frame = () => {
  let [openedTab, setOpenedTab] = useLocalStorageState(
    'piltoverpost.game.frame.tabs.opened',
    'gold-diff'
  )

  const openTab = useCallback((name) => () => setOpenedTab((v) => (v === name ? null : name)), [
    setOpenedTab,
  ])

  const { blueTeam, redTeam, frame, metadata, goldDiff, events } = useGameData()

  if (!frame || !metadata) return null

  const hasItems = !!frame.blueTeam.participants[0].items
  const hasGoldDiff = !!goldDiff

  // Retro-compatibility
  if ((openedTab === 'player-items' && !hasItems) || (openedTab === 'gold-diff' && !hasGoldDiff)) {
    openedTab = 'player-stats'
  }

  return (
    <div className="frame">
      <div className="top-bar">
        <GameTimer frame={frame} />
      </div>
      <div className="score-line">
        <div className="name blue">
          <span className="full">{blueTeam.name}</span>
          <span className="code">{blueTeam.code}</span>
        </div>
        <div className="score">
          <div className="drakes blue">
            <Dragons dragons={frame.blueTeam.dragons} />
          </div>
          <span className="blue">{frame.blueTeam.totalKills}</span>
          <span className="separator">
            <Kill />
          </span>
          <span className="red">{frame.redTeam.totalKills}</span>
          <div className="drakes red">
            <Dragons dragons={frame.redTeam.dragons} />
          </div>
        </div>
        <div className="name red">
          <span className="full">{redTeam.name}</span>
          <span className="code">{redTeam.code}</span>
        </div>
      </div>
      <div className="gold">
        <div className="bar">
          <div className="blue" style={{ flex: goldRatio(frame, 'blue') }}></div>
          <div className="red" style={{ flex: goldRatio(frame, 'red') }}></div>
        </div>
        <div className="values">
          <div className="blue">{formatGold(frame.blueTeam.totalGold)}</div>
          <div>GOLD</div>
          <div className="red">{formatGold(frame.redTeam.totalGold)}</div>
        </div>
      </div>
      <div className="teams-stats">
        <TeamStats team={frame.blueTeam} color="blue" />
        <TeamStats team={frame.redTeam} color="red" />
      </div>
      {frame.state !== 'finished' && (
        <div className="timers">
          <BaronTimer />
          <DragonTimer />
        </div>
      )}
      <div
        className="info-tabs"
        style={{ height: openedTab ? 'auto' : 0, borderTopWidth: openedTab ? '1px' : 0 }}
      >
        {openedTab === 'gold-diff' && (
          <div className="info-tab-gold-diff">
            <GoldGraph data={goldDiff} events={events} />
          </div>
        )}
        {['player-stats', 'player-items'].includes(openedTab) && (
          <div className="info-tab-players">
            <TeamPlayers
              team={frame.blueTeam}
              color="blue"
              type={openedTab === 'player-stats' ? 'stats' : 'items'}
            />
            <TeamPlayers
              team={frame.redTeam}
              color="red"
              type={openedTab === 'player-stats' ? 'stats' : 'items'}
            />
          </div>
        )}
      </div>
      <div className="tabs-toggle">
        {hasItems && (
          <div
            data-tooltip="Toggle items"
            data-tooltip-direction="bottom"
            className={cn({ active: openedTab === 'player-items' })}
          >
            <button onClick={openTab('player-items')}>
              <span role="img" aria-label="Toggle items">
                ⚔️
              </span>
            </button>
          </div>
        )}
        <div
          data-tooltip="Toggle stats"
          data-tooltip-direction="bottom"
          className={cn({ active: openedTab === 'player-stats' })}
        >
          <button onClick={openTab('player-stats')}>
            <span role="img" aria-label="Toggle stats">
              👥
            </span>
          </button>
        </div>
        {hasGoldDiff && (
          <div
            data-tooltip="Toggle gold diff"
            data-tooltip-direction="bottom"
            className={cn({ active: openedTab === 'gold-diff' })}
          >
            <button onClick={openTab('gold-diff')}>
              <span role="img" aria-label="Toggle gold diff">
                📈
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Frame

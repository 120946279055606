import React, { useCallback, useState } from 'react'

import { useLocalStorageState } from 'helpers'
import { pushAnswer } from 'services'
import { Star, StarOutlined } from 'icons'

import './MiniSurvey.scss'

const probabilityOfQuestionShowing = 0.5

const questions = {
  referer: {
    text: 'How did you find The Piltover Post?',
    type: 'choice',
    answers: ['Reddit', 'Twitter', 'Google'],
    other: true,
  },
  /* rate_design: {
    text: 'On a scale of 1 to 5, how would you rate the design of The Piltover Post?',
    type: 'scale',
    min: 1,
    max: 5,
  },
  rate_accuracy: {
    text: 'On a scale of 1 to 5, how would you rate the accuracy of the displayed data?',
    type: 'scale',
    min: 1,
    max: 5,
  },
  discord: {
    text:
      'Would you be interested in a Piltover Post Discord, where you could discuss esports games with our community?',
    type: 'choice',
    answers: ['Yes, would be great!', 'Why not', 'Not interested'],
  }, */
  add_feature: {
    text: 'If you could add one feature to The Piltover Post, what would it be?',
    type: 'text',
  },
  patreon_support: {
    text: 'What would make you support us on Patreon? (Paid feature, merch counterparties, ...)',
    type: 'text',
  },
  one_word_best: {
    text: 'If you had to describe the best thing on The Piltover Post in one word, what would it be?',
    type: 'text',
  },
  one_word_worst: {
    text: 'If you had to describe the worst thing on The Piltover Post in one word, what would it be?',
    type: 'text',
  },
}

const MiniSurvey = ({ minute }) => {
  let [answeredQuestions, setAnsweredQuestions] = useLocalStorageState(
    'piltoverpost.mini-survey',
    [],
    true
  )

  const [question] = useState(() => {
    if (Math.random() < probabilityOfQuestionShowing) {
      return null
    }

    const key = Object.keys(questions).find((q) => !answeredQuestions.includes(q))
    return key ? { key, ...questions[key] } : null
  })

  const [answered, setAnswered] = useState(false)

  const onAnswered = useCallback(() => {
    setAnsweredQuestions([...answeredQuestions, question.key])
    setAnswered(true)
  }, [answeredQuestions, setAnsweredQuestions, question])

  if (!question) return null

  const QuestionType = {
    choice: Choice,
    scale: Scale,
    text: Text,
  }[question.type]

  return (
    <div className="event mini-survey" data-timestamp={`${minute}:00`}>
      <div className="timestamp">{minute}:00</div>
      <div className="content with-border" style={{ textAlign: 'center' }}>
        <small>
          <b>Pardon the interruption,</b> here is a small question to help us improve The Piltover
          Post:
        </small>
        <hr />
        {answered ? (
          <small>Thank you for your answer!</small>
        ) : (
          <>
            <small>
              <b>{question.text}</b>
            </small>
            <br />
            <QuestionType question={question} onAnswered={onAnswered} />
          </>
        )}
      </div>
    </div>
  )
}

const Choice = ({ question: { key, answers, other }, onAnswered }) => {
  const [answer, setAnswer] = useState(null)
  const [comment, setComment] = useState('')

  const sendAnswer = useCallback(() => {
    pushAnswer(key, answer === 'Other' ? comment : answer).then(onAnswered)
  }, [key, answer, comment, onAnswered])

  return (
    <div className="choice">
      {answers.map((text) => (
        <label key={text}>
          <input
            type="radio"
            name="mini-survey"
            value={text}
            checked={answer === text}
            onChange={() => setAnswer(text)}
          />
          <small>{text}</small>
        </label>
      ))}
      {other && (
        <label>
          <input
            type="radio"
            name="mini-survey"
            value="Other"
            checked={answer === 'Other'}
            onChange={() => setAnswer('Other')}
          />
          <small>Other:</small>
          <input
            type="text"
            value={comment}
            onFocus={() => setAnswer('Other')}
            onChange={(e) => setComment(e.target.value)}
          />
        </label>
      )}
      <button disabled={!answer || (answer === 'Other' && comment === '')} onClick={sendAnswer}>
        send
      </button>
    </div>
  )
}

const Scale = ({ question: { key, min, max }, onAnswered }) => {
  const [choice, setChoice] = useState(0)
  const [hover, setHover] = useState(0)

  const sendAnswer = useCallback(() => {
    pushAnswer(key, choice).then(onAnswered)
  }, [key, choice, onAnswered])

  return (
    <>
      <div className="scale" onMouseLeave={() => setHover(0)}>
        {[...new Array(max - min + 1)].map((x, i) => {
          const number = i + min
          const hovered = number <= hover
          const chosen = number <= choice

          const Svg = hovered ? StarOutlined : Star

          return (
            <Svg
              key={number}
              onMouseOver={() => setHover(number)}
              onClick={() => setChoice(number)}
              style={{
                filter: chosen ? 'none' : 'grayscale(100%)',
                opacity: chosen ? '1' : '0.7',
              }}
            />
          )
        })}
      </div>
      <button disabled={choice === 0} onClick={sendAnswer}>
        send
      </button>
    </>
  )
}

const Text = ({ question: { key }, onAnswered }) => {
  const [text, setText] = useState('')

  const sendAnswer = useCallback(() => {
    pushAnswer(key, text).then(onAnswered)
  }, [key, text, onAnswered])

  return (
    <div className="text">
      <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
      <button disabled={text === ''} onClick={sendAnswer}>
        send
      </button>
    </div>
  )
}

export default MiniSurvey

import React from 'react'

import { Link } from 'components'

import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <div>
        <p className="copyright">
          The Piltover Post © {new Date().getFullYear()} - <Link to="/faq">FAQ</Link>
        </p>
        <p className="not-affiliated">
          The Piltover Post isn’t endorsed by Riot Games and doesn’t reflect the views or opinions
          of Riot Games or anyone officially involved in producing or managing League of Legends.
          <br />
          League of Legends and Riot Games are trademarks or registered trademarks of Riot Games,
          Inc. League of Legends © Riot Games, Inc.
        </p>
      </div>
    </footer>
  )
}

export default Footer

import { useEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export const useDisplayWidth = () => {
  const [width, setWidth] = useState(document.body.clientWidth)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setWidth(document.body.clientWidth)
    })

    resizeObserver.observe(document.body)

    return () => resizeObserver.disconnect()
  }, [])

  return width
}

import React from 'react'
import formatRelative from 'date-fns/formatRelative'

import { Image, Link, Loading } from 'components'
import { useEndpoint } from 'services'
import { matchUrl, leagueIds } from 'helpers'

import Match from '../Match'

import './Live.scss'

const Live = ({ nextMatch }) => {
  const { loading, data } = useEndpoint('live', true)

  const matches = data?.schedule.events.filter((m) => leagueIds.includes(m.league.id)) || []

  return (
    <section className="live">
      <div className="outer-border">
        <div className="inner-border">
          <div className="title live">
            <img src="/images/mic.svg" alt="" />
            <h2>Live Matches</h2>
            <img src="/images/mic.svg" alt="" />
          </div>
          <div className="matches">
            {loading && (
              <div className="loading-wrapper">
                <Loading />
              </div>
            )}
            {matches.map((evt) => (
              <Match key={evt.match.id} event={evt} timing="live" />
            ))}
            {matches.length === 0 && !loading && (
              <div className="no-live">
                <Image src="/images/steampunk_lady" className="lady" alt="" />
                <blockquote>
                  You're too early!
                  <br />
                  Come back{' '}
                  {nextMatch ? (
                    <Link to={matchUrl(nextMatch.match)}>
                      {formatRelative(new Date(nextMatch.startTime), new Date())}
                    </Link>
                  ) : (
                    'in a few days'
                  )}
                </blockquote>
              </div>
            )}
          </div>
          <img src="/images/corner.svg" className="corner" alt="" />
          <img src="/images/corner.svg" className="corner" alt="" />
          <img src="/images/corner.svg" className="corner" alt="" />
          <img src="/images/corner.svg" className="corner" alt="" />
          <img src="/images/vertical.png" className="vertical" alt="" />
          <img src="/images/vertical.png" className="vertical" alt="" />
        </div>
      </div>
    </section>
  )
}

export default Live

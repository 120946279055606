import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { ScrollToTop } from './components'

import App from './scenes/App'

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

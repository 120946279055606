import React from 'react'
import cn from 'classnames'
import format from 'date-fns/format'

import { Image, Link } from 'components'
import { useDisplayWidth, matchUrl, matchLabel } from 'helpers'

import './Match.scss'

const Match = ({ event: { match, league, startTime, state, blockName }, timing }) => {
  const width = useDisplayWidth()

  return (
    <Link to={matchUrl(match)} className="match-link" aria-label={matchLabel(league, match)}>
      <div className={cn('match', 'is-' + timing)}>
        {timing === 'live' && <div className="match-title">{blockName}</div>}
        <div className="match-left" data-tooltip={league.name}>
          <Image src={`/images/leagues/${league.slug}`} alt={league.name} className={league.slug} />
        </div>
        <div className="match-center">
          <div className="teams">
            <div
              className={cn('team team-left', { winner: match.teams[0].result?.outcome === 'win' })}
            >
              <div className="code">
                <span>
                  {timing === 'live' && width > 1024 ? match.teams[0].name : match.teams[0].code}
                </span>
                {match.teams[0].record && (
                  <span className="records">
                    {match.teams[0].record.wins}W - {match.teams[0].record.losses}L
                  </span>
                )}
              </div>
              <Image src={`/images/teams/${match.teams[0].code}`} alt="" />
            </div>
            <span className={cn('vs', { unstarted: state === 'unstarted' })}>
              {state === 'unstarted' ? (
                'vs'
              ) : (
                <>
                  <span>{match.teams[0].result.gameWins}</span>
                  <span>{' - '}</span>
                  <span>{match.teams[1].result.gameWins}</span>
                </>
              )}
            </span>
            <div
              className={cn('team team-right', {
                winner: match.teams[1].result?.outcome === 'win',
              })}
            >
              <div className="code">
                <span>
                  {timing === 'live' && width > 1024 ? match.teams[1].name : match.teams[1].code}
                </span>
                {match.teams[1].record && (
                  <span className="records">
                    {match.teams[1].record.wins}W - {match.teams[1].record.losses}L
                  </span>
                )}
              </div>
              <Image src={`/images/teams/${match.teams[1].code}`} alt="" />
            </div>
          </div>
        </div>
        <div className="match-right">
          {timing === 'live' ? (
            <i>Best of {match.strategy.count}</i>
          ) : (
            <span className="time">{format(new Date(startTime), 'h a')}</span>
          )}
          {timing === 'next' && <span className="approx">approx</span>}
        </div>
      </div>
    </Link>
  )
}

export default Match

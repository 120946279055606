import React, { useState } from 'react';

import { Link } from 'components';
import { calculateOdds } from 'helpers';

import MiniSurvey from './MiniSurvey';
import { useRoadToWorlds } from 'services/roadToWorlds';

const Minute = ({ event, ...rest }) => {
  const minute = Number(event.timestamp.split(':')[0]);

  switch (minute) {
    case 3:
      return <MiniSurvey minute={minute} />;
    case 15:
      return <AtFifteen event={event} {...rest} />;
    case 20:
      return <RoadToWorlds minute={minute} {...rest} />;
    // case 10:
    case 30:
      // case 40:
      // case 50:
      // return <DiscordIntegration minute={minute} />
      return <Ad minute={minute} />;
    default:
      return null;
  }
};

const AtFifteen = ({ event, blueTeam, redTeam }) => {
  const { frame } = event;

  if (!frame) return null;

  const odds = calculateOdds(frame.blueTeam, frame.redTeam);

  return (
    <div className="event at-15">
      <div className="timestamp">15:00</div>
      <div className="content with-border center">
        <small>
          Based on the{' '}
          <a href="https://oracleselixir.com/tools/egr">Early-Game Win Probability Calculator</a> by
          Oracle's Elixir,
        </small>
        <br />
        <small style={{ margin: '8px 0', display: 'inline-flex', fontSize: '15px' }}>
          <b>{odds > 50 ? blueTeam.name : redTeam.name}</b> has a{' '}
          <b>{(odds > 50 ? odds : 100 - odds).toFixed(1)}%</b> chance of winning!
        </small>
        <br />
        <small>
          Will <strong>{odds > 50 ? redTeam.name : blueTeam.name}</strong> make a comeback?
        </small>
      </div>
    </div>
  );
};

export default Minute;

const Ad = ({ minute, message }) => {
  /* const messages = [
    <>
      <small>
        Do you like <b>The Piltover Post</b>?
      </small>
      <br />
      <small>
        Then please consider supporting us through our <Link patreon />! 🙏
      </small>
    </>,
    <>
      <small>
        Wait, so The Piltover Post is free AND without ads?{' '}
        <span role="img" aria-label="Mind Blown emoji">
          🤯
        </span>
      </small>
      <br />
      <small>
        <b>Yes!</b> But to keep allowing time to maintain and improve it, and cover the hosting
        fees, <b>we need your support!</b>
      </small>
      <br />
      <small>
        <b>
          Check out our <Link patreon /> to support us!
        </b>
      </small>
    </>,
    <>
      <small>
        We want to pay <b>(semi-)professionnal caster to write what happens in the game</b>,<br />
        for an improved experience on The Piltover Post.{' '}
        <span role="img" aria-label="Studio Microphone emoji">
          🎙
        </span>
      </small>
      <br />
      <small>
        <b>But we can't do that without your financial support.</b> Please consider supporting us
        through our <Link patreon />
      </small>
    </>,
  ] */

  const messages = [
    <>
      <small>
        Do you like <b>The Piltover Post</b>?
      </small>
      <br />
      <small>
        Then please share it with your friends!{' '}
        <span role="img" aria-label="Thank You emoji">
          🙏
        </span>
      </small>
    </>,
  ];

  const [rnd] = useState(
    message !== undefined ? message : Math.floor(Math.random() * messages.length)
  );

  return (
    <div className="event ad" data-timestamp={`${minute}:00`}>
      <div className="timestamp">{minute}:00</div>
      <div className="content with-border center">{messages[rnd]}</div>
    </div>
  );
};

/*const FantasyLEC = ({ minute, match }) => {
  if (match?.league?.name === 'LEC') {
    return (
      <div className="event newsletter">
        <div className="timestamp">{minute}:00</div>
        <div className="content with-border center">
          <small>
            Do you play in the Fantasy LEC?
            <br />
            Then come and join us in our{' '}
            <Link
              to="https://lec.superfantasylol.com/lobby/5299f85c-b0d6-4d8a-9a12-9233974956c8?hash=A6NEU2wfiMklJTrMt8n6zAXPpT2KtCr5gChq"
              external
            >
              official lobby
            </Link>
            !
          </small>
        </div>
      </div>
    )
  }

  return <Ad minute={minute} />
}*/

/*const Newsletter = ({ minute }) => {
  return (
    <div className="event newsletter">
      <div className="timestamp">{minute}:00</div>
      <div className="content with-border">
        <small>
          Don't miss any matches!
          <br />
          Receive weekly results and schedules in your inbox by subscribing to the{' '}
          <Link to="http://eepurl.com/hdJI5T" external>
            Newsletter
          </Link>
          !
        </small>
      </div>
    </div>
  )
}*/

/*const DiscordIntegration = ({ minute }) => {
  return (
    <div className="event newsletter">
      <div className="timestamp">{minute}:00</div>
      <div className="content with-border center">
        <small>
          ℹ️ We are working on a paid <b>Discord integration</b> for The Piltover Post.
          <br />
          If you are interested in beta-testing it, please contact us <Link twitter /> or{' '}
          <Link email />! 🙏
        </small>
      </div>
    </div>
  )
}*/

const RoadToWorlds = ({ minute, match }) => {
  const data = useRoadToWorlds();

  if (match.league.slug !== 'lec' || !data || data.current.period === 'worlds') {
    return null;
  }

  const team1Probabilities = data.results.finals.probabilities[match.teams[0].code];
  const team2Probabilities = data.results.finals.probabilities[match.teams[1].code];

  const team1Probability =
    (team1Probabilities[0] +
      team1Probabilities[1] +
      team1Probabilities[2]) *
    100;
  const team2Probability =
    (team2Probabilities[0] +
      team2Probabilities[1] +
      team2Probabilities[2]) *
    100;

  let teamACode, teamBCode, teamAProba, teamBProba;

  if (team1Probability > team2Probability) {
    teamACode = match.teams[0].code;
    teamBCode = match.teams[1].code;
    teamAProba = team1Probability;
    teamBProba = team2Probability;
  } else {
    teamACode = match.teams[1].code;
    teamBCode = match.teams[0].code;
    teamAProba = team2Probability;
    teamBProba = team1Probability;
  }

  return (
    <div className="event newsletter">
      <div className="timestamp">{minute}:00</div>
      <div className="content with-border center">
        <small>
          <b>Did you know?</b>
          <br />
          <b>{teamACode}</b> currently has a <b>{teamAProba.toFixed(1)}%</b> chance of qualifying
          for Worlds 2024!
          <br />
          On their side, <b>{teamBCode}</b> currently has a <b>{teamBProba.toFixed(1)}%</b> chance.
        </small>
        <br />
        <br />
        <small>
          Check out all LEC teams' chances of qualifying for <b>Worlds 2024</b> on{' '}
          <Link roadToWorlds />!
        </small>
      </div>
    </div>
  );
};

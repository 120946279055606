import React from 'react'
import { Switch, Route } from 'react-router-dom'

import './Pages.scss'

const Pages = () => {
  return (
    <>
      <Switch>
        <Route>404</Route>
      </Switch>
    </>
  )
}

export default Pages

import React, { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'

import { Baron, Elder, Dragon as NeutralDragon } from 'icons'
import { timestampToSeconds } from 'helpers'

import { Dragon } from './Dragons'

import { useGameData } from '../../GameContext'

import './Timers.scss'

const useSecondsElapsed = () => {
  const { frame } = useGameData()

  const [seconds, setSeconds] = useState(null)

  useEffect(() => {
    if (!frame || isNaN(frame.msElapsed)) return

    const secondsElapsed = Math.floor(frame.msElapsed / 1000)
    setSeconds(secondsElapsed)

    if (frame.state === 'in_game') {
      const interval = setInterval(() => {
        setSeconds((s) => s + 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [frame])

  return seconds
}

const secondsToClock = (seconds) => {
  seconds = Math.max(seconds, 0)
  return (
    <div className="clock">
      <span>{Math.floor(seconds / 60)}</span>
      <span>:</span>
      <span>{((seconds % 60) + '').padStart(2, '0')}</span>
    </div>
  )
}

export const GameTimer = () => {
  const { frame } = useGameData()
  const seconds = useSecondsElapsed()

  return seconds !== null ? (
    <div className="timer">
      <div className={cn('state', frame.state)} />
      {secondsToClock(seconds)}
    </div>
  ) : null
}

const _18MIN = 18 * 60
const _20MIN = 20 * 60

const BARON_RESPAWN = 6 * 60
const BARON_BUFF = 3 * 60

export const BaronTimer = () => {
  const { events } = useGameData()

  const secondsElapsed = useSecondsElapsed()

  const lastBaronEvent = useMemo(() => {
    return events
      .filter((evt) => evt.type === 'baron')
      .sort((a, b) => a.score - b.score)
      .pop()
  }, [events])

  const lastBaronPowerplayEvent = useMemo(() => {
    return events
      .filter((evt) => evt.type === 'baron powerplay')
      .sort((a, b) => a.score - b.score)
      .pop()
  }, [events])

  const secondsElapsedAtBaronEvent = timestampToSeconds(lastBaronEvent?.timestamp)
  const secondsElapsedAtBaronPowerplayEvent = timestampToSeconds(lastBaronPowerplayEvent?.timestamp)
  const secondsSinceBaronEvent = lastBaronEvent
    ? secondsElapsed - secondsElapsedAtBaronEvent
    : BARON_RESPAWN

  // Don't show baron timer before 18min
  if (secondsElapsed < _18MIN) {
    return <div className="baron-timer empty"></div>
  }

  if (secondsElapsed < _20MIN) {
    return (
      <div className="baron-timer spawn">
        <Baron />
        <div className="right">
          <span>Spawns in</span>
          {secondsToClock(_20MIN - secondsElapsed)}
        </div>
      </div>
    )
  }

  if (
    lastBaronEvent &&
    (!secondsElapsedAtBaronPowerplayEvent ||
      secondsElapsedAtBaronPowerplayEvent < secondsElapsedAtBaronEvent)
  ) {
    return (
      <div className={cn('baron-timer buff', lastBaronEvent.team)}>
        <Baron />
        <div className="right">
          <span>Buff ends in</span>
          {secondsToClock(secondsElapsedAtBaronEvent + BARON_BUFF - secondsElapsed)}
        </div>
      </div>
    )
  }

  if (secondsSinceBaronEvent < BARON_RESPAWN) {
    return (
      <div className="baron-timer respawn">
        <Baron />
        <div className="right">
          <span>Respawns in</span>
          {secondsToClock(BARON_RESPAWN - secondsSinceBaronEvent)}
        </div>
      </div>
    )
  }

  return (
    <div className="baron-timer">
      <Baron />
      <div className="right">
        <span>Alive</span>
      </div>
    </div>
  )
}

const DRAGON_RESPAWN = 5 * 60
const ELDER_RESPAWN = 6 * 60
const ELDER_BUFF = 2.5 * 60

export const DragonTimer = () => {
  const { frame, events } = useGameData()

  const secondsElapsed = useSecondsElapsed()

  const lastDragonEvent = useMemo(() => {
    return events
      .filter((evt) => evt.type === 'dragon')
      .sort((a, b) => a.score - b.score)
      .pop()
  }, [events])

  const lastElderPowerplayEvent = useMemo(() => {
    return events
      .filter((evt) => evt.type === 'elder powerplay')
      .sort((a, b) => a.score - b.score)
      .pop()
  }, [events])

  const dragonCount = frame.blueTeam.dragons.length + frame.redTeam.dragons.length + 1
  const isElder = frame.blueTeam.dragons.length >= 4 || frame.redTeam.dragons.length >= 4
  const wasElder =
    frame.blueTeam.dragons.includes('elder') || frame.redTeam.dragons.includes('elder')
  const RESPAWN = isElder ? ELDER_RESPAWN : DRAGON_RESPAWN

  const secondsElapsedAtDragonEvent = timestampToSeconds(lastDragonEvent?.timestamp)
  const secondsElapsedAtElderPowerplayEvent = timestampToSeconds(lastElderPowerplayEvent?.timestamp)
  const secondsSinceDragonEvent = secondsElapsedAtDragonEvent
    ? secondsElapsed - secondsElapsedAtDragonEvent
    : RESPAWN

  if (secondsElapsed < DRAGON_RESPAWN) {
    return (
      <div className="dragon-timer spawn">
        <NeutralDragon />
        <div className="right">
          <span>Spawns in</span>
          {secondsToClock(DRAGON_RESPAWN - secondsElapsed)}
        </div>
      </div>
    )
  }

  if (
    wasElder &
    (!secondsElapsedAtElderPowerplayEvent ||
      secondsElapsedAtElderPowerplayEvent < secondsElapsedAtDragonEvent)
  ) {
    return (
      <div className={cn('dragon-timer buff', lastDragonEvent.team)}>
        <Elder />
        <div className="right">
          <span>Buff ends in</span>
          {secondsToClock(secondsElapsedAtDragonEvent + ELDER_BUFF - secondsElapsed)}
        </div>
      </div>
    )
  }

  const Icon = isElder ? (
    <Elder />
  ) : dragonCount > 3 ? (
    <Dragon dragon={lastDragonEvent.dragon} />
  ) : (
    <NeutralDragon />
  )

  if (secondsSinceDragonEvent < RESPAWN) {
    const isSpawn = isElder && !wasElder

    return (
      <div className="dragon-timer">
        {Icon}
        <div className="right">
          <span>{isSpawn ? 'Spawns' : 'Respawns'} in</span>
          {secondsToClock(RESPAWN - secondsSinceDragonEvent)}
        </div>
      </div>
    )
  }

  return (
    <div className="dragon-timer">
      {Icon}
      <div className="right">
        <span>Alive</span>
      </div>
    </div>
  )
}

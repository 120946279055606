import React from 'react'

const Text = ({ event }) => {
  switch (event.text) {
    case 'welcome':
      return <Welcome event={event} />
    case 'bye':
      return <Bye event={event} />
    default:
      throw new Error(event)
  }
}

const Welcome = ({ event }) => {
  return (
    <div className="event text welcome">
      <div className="content with-double-border">
        <small>
          Welcome to <b>The Piltover Post</b>! Today's game is: <b>{event.name}</b>. Who's your
          favorite?
        </small>
      </div>
    </div>
  )
}

const Bye = ({ event }) => {
  return (
    <div className="event text bye">
      <div className="content with-double-border">
        <small>
          Thank you for using <b>The Piltover Post</b>, see you at the next game!
        </small>
      </div>
    </div>
  )
}

export default Text

import React, { useContext, useEffect, useMemo, useState } from 'react'

// https://ddragon.leagueoflegends.com/api/versions.json
const lastKnownVersion = '13.1.1'

const pickDataDragonVersion = (patchVersion) => {
  return patchVersion.split('.').slice(0, 2).join('.') + '.1'
}

const cache = {}

const fetchItems = (version) => {
  if (cache[version]) return Promise.resolve(cache[version])

  return fetch(`https://ddragon.leagueoflegends.com/cdn/${version}/data/en_GB/item.json`)
    .then((r) => r.json())
    .then((data) => {
      cache[version] = data
      return data
    })
}

const DataDragonContext = React.createContext()

export const useDataDragon = () => useContext(DataDragonContext)

export const DataDragonProvider = ({ metadata, children }) => {
  const version = pickDataDragonVersion(metadata?.patchVersion || lastKnownVersion)

  const [items, setItems] = useState(null)

  useEffect(() => {
    fetchItems(version).then(setItems)
  }, [version])

  const championSlug = (name) =>
    ({
      FiddleSticks: 'Fiddlesticks',
    }[name] || name)

  const value = useMemo(
    () => ({
      version,
      items,
      championPortraitUrl: (champion) =>
        `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${championSlug(
          champion
        )}.png`,
      itemImageUrl: (id) => `https://ddragon.leagueoflegends.com/cdn/${version}/img/item/${id}.png`,
    }),
    [version, items]
  )

  return <DataDragonContext.Provider value={value}>{children}</DataDragonContext.Provider>
}

import React, { useMemo } from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet-async'

import { Link, Loading } from 'components'
import { useEndpoint } from 'services'
import { useDisplayWidth, useFilteredLeagueIds } from 'helpers'

import MatchList from './MatchList'
import Live from './Live'
import News from './News'

import './Home.scss'

const Home = ({ location: { hash } }) => {
  if (!hash) hash = '#schedule'

  const leagueIds = useFilteredLeagueIds()

  const { loading, data } = useEndpoint('schedule?leagueId=' + leagueIds.join(','), true)

  const schedule = data?.schedule.events || []

  const { next, previous } = useMemo(
    () => ({
      // live: schedule.filter((evt) => evt.state === 'inProgress'),
      next: schedule.filter((evt) => evt.state === 'unstarted').slice(0, 10),
      previous: schedule
        .filter((evt) => evt.state === 'completed')
        .reverse()
        .slice(0, 10),
    }),
    [schedule]
  )

  const width = useDisplayWidth()

  const showNews = window.localStorage.getItem('piltoverpost.ff.news')

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://piltoverpost.gg" />
      </Helmet>
      <main id="home">
        <Live nextMatch={next?.[0]} />
        <section className="content">
          <div className={cn('tabs', { 'is-active': width < 1024 })}>
            <Link to="#schedule" className={cn('tab-button', { active: hash === '#schedule' })}>
              <div className="title schedule">
                <img src="/images/clock.svg" alt="" />
                <h2>Schedule</h2>
              </div>
            </Link>
            <Link to="#results" className={cn('tab-button', { active: hash === '#results' })}>
              <div className="title">
                <img src="/images/trophy.svg" alt="" />
                <h2>Results</h2>
              </div>
            </Link>
          </div>
          <div className={cn('columns', { 'is-tabs-active': width < 1024 })}>
            <div className={cn('column', { active: hash === '#schedule' })}>
              {loading ? <Loading /> : <MatchList matches={next} timing="next" />}
            </div>
            <div className={cn('column', { active: hash === '#results' })}>
              {loading ? <Loading /> : <MatchList matches={previous} timing="previous" />}
            </div>
          </div>
        </section>
        {showNews && <section className="news">{loading ? <Loading /> : <News />}</section>}
      </main>
    </>
  )
}

export default Home

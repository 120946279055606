import React from 'react'

import { Cloud, Infernal, Mountain, Ocean, Hextech, Chemtech, Elder } from 'icons'

export const Dragon = ({ dragon }) => {
  const Dragon = {
    cloud: Cloud,
    infernal: Infernal,
    mountain: Mountain,
    ocean: Ocean,
    elder: Elder,
    hextech: Hextech,
    chemtech: Chemtech,
  }[dragon]

  return (
    <span data-tooltip={dragon}>
      <Dragon />
    </span>
  )
}

export const Dragons = ({ dragons }) =>
  dragons.map((dragon, i) => <Dragon key={dragon + i} dragon={dragon} />)

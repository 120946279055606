import React from 'react'

import { Image } from 'components'
import { unifyTeamCode } from 'helpers'

const Winner = ({ event, blueTeam, redTeam }) => {
  const team = [blueTeam, redTeam].find((t) => t.code === unifyTeamCode(event.winner))

  return (
    <div className="event winner">
      <div className="content">
        <h4>{team?.name || event.winner} wins!</h4>
        {team?.name && <Image src={`/images/teams/${unifyTeamCode(event.winner)}`} alt="" />}
      </div>
    </div>
  )
}

export default Winner

import React, { useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import isTomorrow from 'date-fns/isTomorrow'

import Match from './Match'
import { useFilter } from 'components'

const MatchList = ({ matches, timing }) => {
  const { openModal } = useFilter()

  const groups = useMemo(
    () => groupBy(matches, (m) => format(new Date(m.startTime), 'yyyy-MM-dd')),
    [matches]
  )

  if (matches.length === 0) {
    if (timing === 'next') {
      return (
        <div className="match-list">
          <p style={{ textAlign: 'center', padding: '2em' }}>
            <i>
              No scheduled matches with{' '}
              <button className="open-filter" onClick={openModal}>
                current filter
              </button>
            </i>
          </p>
        </div>
      )
    } else {
      return (
        <div className="match-list">
          <p style={{ textAlign: 'center', padding: '2em' }}>
            <i>
              No results with{' '}
              <button className="open-filter" onClick={openModal}>
                current filter
              </button>
            </i>
          </p>
        </div>
      )
    }
  }

  return (
    <div className="match-list">
      {Object.entries(groups).map(([date, group]) => {
        const startDate = new Date(group[0].startTime)
        const day = isToday(startDate)
          ? timing === 'next'
            ? 'Today'
            : 'Earlier today'
          : isYesterday(startDate)
          ? 'Yesterday'
          : isTomorrow(startDate)
          ? 'Tomorrow'
          : format(startDate, 'EEEE')

        return (
          <div className="match-day" key={date}>
            <div className="match-date">
              <span>{day}</span> - {format(startDate, 'MMMM d')}
            </div>
            {group.map((evt) => (
              <Match key={evt.match.id} event={evt} timing={timing} />
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default MatchList

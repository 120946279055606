import firebase from 'firebase/app'
import 'firebase/firestore'
import { useEffect, useState } from 'react'

export const app = firebase.initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
})

export const db = app.firestore()

export const useNews = (league = 'global') => {
  const [data, setData] = useState({
    loading: true,
  })

  useEffect(() => {
    db.collection('news')
      .doc(league)
      .get()
      .then((doc) => {
        setData({ loading: false, ...doc.data() })
      })
  }, [league, setData])

  return data
}

export const useGameData = (game) => {
  const [data, setData] = useState({
    loading: true,
  })

  useEffect(() => {
    return db
      .collection('games')
      .doc(game.id)
      .onSnapshot((doc) => {
        const data = doc.data()

        try {
          setData({
            loading: false,
            data: data
              ? {
                  metadata: unserializeMetadata(data.metadata),
                  frame: unserializeFrame(data.frame),
                  events: unserializeEvents(data.events),
                  goldDiff: unserializeGoldDiff(data.goldDiff),
                }
              : null,
          })
        } catch (error) {
          console.log(error)
          setData({
            loading: false,
            error,
          })
        }
      })
  }, [game.id])

  return data
}

const unserializeMetadata = (metadata) => {
  if (!metadata) return null
  metadata = JSON.parse(metadata)
  return {
    patchVersion: metadata[0],
    participants: metadata[1].map((p) => ({
      id: p[0],
      name: p[1],
      champId: p[2],
    })),
  }
}

const unserializeFrame = (frame) => {
  if (!frame) return null
  frame = JSON.parse(frame)
  return {
    msElapsed: frame[0],
    state: frame[1],
    blueTeam: {
      totalGold: frame[2][0],
      totalKills: frame[2][1],
      towers: frame[2][2],
      inhibitors: frame[2][3],
      dragons: frame[2][4],
      barons: frame[2][5],
      participants: frame[2][6].map((p) => ({
        id: p[0],
        level: p[1],
        kills: p[2],
        deaths: p[3],
        assists: p[4],
        gold: p[5],
        alive: p[6],
        creeps: p[7],
        items: p[8],
      })),
    },
    redTeam: {
      totalGold: frame[3][0],
      totalKills: frame[3][1],
      towers: frame[3][2],
      inhibitors: frame[3][3],
      dragons: frame[3][4],
      barons: frame[3][5],
      participants: frame[3][6].map((p) => ({
        id: p[0],
        level: p[1],
        kills: p[2],
        deaths: p[3],
        assists: p[4],
        gold: p[5],
        alive: p[6],
        creeps: p[7],
        items: p[8],
      })),
    },
  }
}

const unserializeEvent = (event) => {
  const [type, a, b, c, d, e, f, g] = event

  switch (type) {
    case 'text':
      switch (a) {
        case 'welcome':
          return {
            type,
            text: a,
            name: b,
            score: -1,
          }
        case 'bye':
          return {
            type,
            text: a,
            score: 20000,
          }
        default:
          console.error(event)
          return null
      }
    case 'tower':
    case 'inhibitor':
    case 'baron':
      return {
        type,
        timestamp: a,
        team: b,
        count: c,
      }
    case 'dragon':
      return {
        type,
        timestamp: a,
        team: b,
        dragon: c,
        dragons: d,
      }
    case 'minute':
      return {
        type,
        timestamp: a,
        frame: b ? unserializeFrame(b) : null,
      }
    case 'gold threshold':
      return {
        type,
        timestamp: a,
        from: b,
        to: c,
      }
    case 'baron powerplay':
    case 'elder powerplay':
      return {
        type,
        timestamp: a,
        team: b,
        diff: c,
      }
    case 'pause':
      return {
        type,
        timestamp: a,
        pause: b,
        msElapsedInPause: c,
      }
    case 'chronobreak':
      return {
        type,
        timestamp: a,
        offset: b,
      }
    case 'winner':
      return {
        type,
        winner: a,
        score: 10000,
      }
    case 'ace':
      return {
        type,
        timestamp: a,
        team: b,
      }
    case 'kill':
      return {
        type,
        timestamp: a,
        killId: b,
        deathId: c,
        assistsId: d,
        streak: e,
        multi: f,
        shutdown: g,
      }
    case 'first blood':
      return {
        type,
        timestamp: a,
        killId: b,
        deathId: c,
        assistsId: d,
      }
    default:
      console.error(event)
      return null
  }
}

const unserializeEvents = (events) => {
  events = (events || []).map((evt) => JSON.parse(evt))

  events = events
    .map(unserializeEvent)
    .filter((evt) => evt)
    .map((evt) => {
      if (!evt.score) {
        const [m, s] = evt.timestamp.split(':').map(Number)
        evt.score = m * 60 + s
      }
      return evt
    })

  events = events.sort((a, b) => a.score - b.score)

  return events
}

const unserializeGoldDiff = (goldDiff) => {
  if (!goldDiff) return null
  return goldDiff.map((diff) => JSON.parse(diff)).map(([x, y]) => ({ x, y }))
}

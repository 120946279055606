import React from 'react'
import cn from 'classnames'

import { eventColor } from 'helpers'
import { Kill as KillSvg } from 'icons'
import { Champion } from 'components'

import './Kill.scss'

const announcement = (event, killer) => {
  if (!killer) return ['Executed']

  const name = killer.name.split(' ').slice(1).join(' ')
  const list = []
  if (event.type === 'first blood') list.push('First Blood!')
  if (event.multi === 5) list.push('PENTAKILL!!!')
  if (event.multi === 4) list.push('QuadraKill')
  if (event.streak === 7) list.push(`${name} is Godlike`)
  if (event.streak >= 8) list.push(`${name} is Legendary`)
  if (event.shutdown >= 3) list.push(`Shutdown!`)

  return list
}

const Kill = ({ event, metadata }) => {
  const killer = metadata.participants.find((p) => p.id === event.killId)
  const dead = metadata.participants.find((p) => p.id === event.deathId)
  const assists = metadata.participants.filter((p) => event.assistsId.includes(p.id))

  return (
    <div
      className={cn('event', event.type.replace(/\s/g, '-'), eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        {announcement(event, killer).map((ann) => (
          <div key={ann} className="announcement">
            {ann}
          </div>
        ))}
        <div className="grid">
          <div className="dead">
            <Champion id={dead.champId} dead />
          </div>
          <div className="kill-icon">
            <KillSvg />
          </div>
          <div className="killers">
            <div className="killer">{killer && <Champion id={killer.champId} />}</div>
            <div className="assists">
              {assists.map((assist) => (
                <div key={assist.id} className="assist">
                  <Champion id={assist.champId} size="30px" />
                </div>
              ))}
            </div>
          </div>
          <div className="dead-name">{dead.name}</div>
          <div className="killer-name">{killer ? killer.name : ''}</div>
        </div>
      </div>
    </div>
  )
}

export default Kill

import React, { useContext, useMemo } from 'react'
import cn from 'classnames'

import { eventColor } from 'helpers'

import {
  Ace,
  Baron,
  Dragon,
  GoldThreshold,
  Chronobreak,
  Inhibitor,
  Kill,
  Minute,
  Pause,
  Powerplay,
  Text,
  Tower,
  Winner,
} from './components'

import { GameContext } from '../GameContext'

import './Events.scss'

const Events = () => {
  const { events, ...props } = useContext(GameContext)

  const sortedEvents = useMemo(() => [...events].reverse(), [events])

  return (
    <div className="events">
      {sortedEvents.map((evt) => (
        <Event key={JSON.stringify(evt)} event={evt} {...props} />
      ))}
    </div>
  )
}

const Event = (props) => {
  const Component =
    {
      ace: Ace,
      baron: Baron,
      chronobreak: Chronobreak,
      dragon: Dragon,
      'gold threshold': GoldThreshold,
      inhibitor: Inhibitor,
      'first blood': Kill,
      kill: Kill,
      minute: Minute,
      pause: Pause,
      'baron powerplay': Powerplay,
      'elder powerplay': Powerplay,
      text: Text,
      tower: Tower,
      winner: Winner,
    }[props.event.type] || DefaultEvent

  return <Component {...props} />
}

const DefaultEvent = ({ event, metadata }) => {
  const color = eventColor(event, metadata)
  return (
    <div className={cn('event', color)} data-timestamp={event.timestamp}>
      <div className="timestamp">{event.timestamp}</div>
      <div className="content" style={{ wordBreak: 'break-word' }}>
        <small>{JSON.stringify(event)}</small>
      </div>
    </div>
  )
}

export default Events

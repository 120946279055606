import React from 'react'
import cn from 'classnames'

import { eventColor } from 'helpers'
import { Champion } from 'components'

const Dragon = ({ event, blueTeam, redTeam, metadata }) => {
  const { name } = { blueTeam, redTeam }[event.team + 'Team']

  const teamSize = metadata.participants.length / 2
  const offset = event.team === 'blue' ? teamSize : 0
  const deadPlayers = metadata.participants.slice(offset, teamSize + offset)

  return (
    <div
      className={cn('event', 'ace thefuck', eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <div className="announcement">ACE by {name}!!!</div>
        <div className="teams">
          <div className="players">
            {deadPlayers.map((p) => (
              <Champion key={p.id} id={p.champId} size="40px" dead />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dragon

import React from 'react'
import cn from 'classnames'

import { eventColor, nth } from 'helpers'
import { Cloud, Infernal, Mountain, Ocean, Hextech, Chemtech, Elder } from 'icons'

const Dragon = ({ event, blueTeam, redTeam, metadata }) => {
  const Icon = {
    cloud: Cloud,
    infernal: Infernal,
    mountain: Mountain,
    ocean: Ocean,
    hextech: Hextech,
    chemtech: Chemtech,
    elder: Elder,
  }[event.dragon]

  const { code } = { blueTeam, redTeam }[event.team + 'Team']

  return (
    <div
      className={cn('event', 'dragon', eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <div className="announcement">
          <span className="dragon-name">{event.dragon}</span>
          {event.dragons.length >= 4 && event.dragon !== 'elder' ? 'Soul' : 'dragon'} ({code}'s{' '}
          {event.dragon === 'elder'
            ? nth(event.dragons.filter((d) => d === 'elder').length)
            : nth(event.dragons.length)}
          )
        </div>
        <Icon />
      </div>
    </div>
  )
}

export default Dragon

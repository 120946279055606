import React from 'react'
import { Helmet } from 'react-helmet-async'

import { Articles, Link } from 'components'

import './News.scss'

const leagues = {
  global: 'Global',
  worlds: 'Worlds',
  msi: 'MSI',
  lec: 'LEC',
  lcs: 'LCS',
  lck: 'LCK',
}

const News = ({
  match: {
    params: { league = 'global' },
  },
}) => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://piltoverpost.gg/news" />
      </Helmet>
      <div id="news" className="container">
        <h1 align="center">Latest articles</h1>

        <div className="leagues">
          {Object.entries(leagues).map(([key, name]) => (
            <Link key={key} to={`/news/${key}`}>
              {name}
            </Link>
          ))}
        </div>

        <Articles league={league} />
      </div>
    </>
  )
}

export default News

import React from 'react'
import cn from 'classnames'

import { useDataDragon } from 'services'

import './Champion.scss'

const championName = (id) =>
  ({
    MonkeyKing: 'Wukong',
  }[id] || id)

const Champion = ({ id, size, dead }) => {
  const { championPortraitUrl } = useDataDragon()

  return (
    <div data-tooltip={championName(id)} style={{ display: 'flex', alignItems: 'center' }}>
      <div className={cn('champion-portrait', { dead })} style={{ '--size': size || '50px' }}>
        <img src={championPortraitUrl(id)} alt={championName(id)} />
      </div>
    </div>
  )
}

export default Champion

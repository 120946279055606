export const eventColor = (event, metadata) => {
  const teamSize = metadata.participants.length / 2

  if (event.team) return event.team

  if (event.deathId) return event.deathId <= teamSize ? 'red' : 'blue'

  if (event.to !== undefined) return event.to > event.from ? 'blue' : 'red'

  return 'grey'
}

export const nth = (number) => {
  const lastDigit = number % 10
  const isTeen = number % 100 >= 11 && number % 100 <= 13

  if (lastDigit === 1 && !isTeen) {
    return `${number}st`
  }
  if (lastDigit === 2 && !isTeen) {
    return `${number}nd`
  }
  if (lastDigit === 3 && !isTeen) {
    return `${number}rd`
  }
  return `${number}th`
}

export const formatTimeLasted = (ms, withSeconds = false) => {
  const seconds = Math.floor(ms / 1000)
  if (seconds < 60) {
    if (withSeconds) {
      if (seconds < 1) {
        return 'less than a second'
      }
      return `${seconds} second${seconds > 1 ? 's' : ''}`
    } else {
      return 'less than a minute'
    }
  }
  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`
  }
  const hours = Math.floor(minutes / 60)
  const extraMinutes = minutes % 60
  return `${hours} hour${hours > 1 ? 's' : ''}` + extraMinutes > 0
    ? `and ${extraMinutes} minutes`
    : ''
}

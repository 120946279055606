import React, { useContext, useEffect, useState } from 'react';

let cache;

const fetchData = () => {
  if (cache) {
    return Promise.resolve(cache);
  }

  return fetch('https://road-to-worlds.gg/state-pp.json')
    .then((r) => r.json())
    .then((data) => {
      cache = data;
      return data;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

const RoadToWorldsContext = React.createContext();

export const useRoadToWorlds = () => useContext(RoadToWorldsContext);

export const RoadToWorldsProvider = ({ children }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData().then(setData);
  }, []);

  return <RoadToWorldsContext.Provider value={data}>{children}</RoadToWorldsContext.Provider>;
};

import React, { useEffect, useState } from 'react'
import fromUnixTime from 'date-fns/fromUnixTime'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import { useNews } from 'services'
import { Loading } from 'components'

import './Articles.scss'
import { useDisplayWidth } from 'helpers'

const Articles = ({ league, noFeatured, max = 100 }) => {
  const { loading, articles = [] } = useNews(league)

  const width = useDisplayWidth()

  const [featuredCount, setFeaturedCount] = useState(0)

  useEffect(() => {
    let featured = 4

    if (width < 550) {
      featured = 0
    } else if (width < 880) {
      featured = Math.floor(width / 220)
    }

    setFeaturedCount(featured)
  }, [width])

  if (loading) {
    return <Loading />
  }

  const featured = articles.slice(0, noFeatured ? 0 : Math.min(max, featuredCount))
  const list = articles.slice(noFeatured ? 0 : Math.min(max, featuredCount), max)

  return (
    <div className="articles">
      <div className="featured">
        {featured.map((article) => (
          <article key={article.id}>
            <a href={article.url} aria-label={article.title}>
              <div className="img-wrapper">
                <img src={article.image} alt="" />
              </div>
              <div className="infos">
                <span className="source">{article.source}</span>
                <p className="news-title">{article.title}</p>
                <span className="timestamp">
                  {formatDistanceToNow(fromUnixTime(article.timestamp), { addSuffix: true })}
                </span>
              </div>
            </a>
          </article>
        ))}
      </div>
      {list.length > 0 && (
        <div className="list">
          {list.map((article) => (
            <article key={article.id}>
              <a href={article.url} aria-label={article.title}>
                <div className="infos">
                  <p className="news-title">{article.title}</p>
                  <div className="bottom">
                    <span className="source">{article.source}</span>
                    <span className="timestamp">
                      {formatDistanceToNow(fromUnixTime(article.timestamp), { addSuffix: true })}
                    </span>
                  </div>
                </div>
                <div className="img-wrapper">
                  <img src={article.image} alt="" />
                </div>
              </a>
            </article>
          ))}
        </div>
      )}
    </div>
  )
}

export default Articles

exports.default = {
  leagues: {
    /* International competitions */
    intl: [
      {
        code: 'Worlds', // Short code (internal use)
        image: 'worlds', // Image name (from lolesports)
        name: 'Worlds',
        region: 'International',
        lolesportsId: '98767975604431411',
        pandascoreId: 297,
      },
      {
        code: 'MSI',
        image: 'msi',
        name: 'Mid-Season Invitational',
        region: 'International',
        lolesportsId: '98767991325878492',
        pandascoreId: 300,
      },
      {
        code: 'ASE',
        image: 'all-star',
        name: 'All Star Event',
        region: 'International',
        lolesportsId: '98767991295297326',
        pandascoreId: 296,
      },
    ],
    /* Major leagues */
    major: [
      {
        code: 'LEC',
        image: 'lec',
        name: 'LoL European Championship',
        region: 'Europe',
        lolesportsId: '98767991302996019',
        pandascoreId: 4197,
      },
      {
        code: 'LCS',
        image: 'lcs',
        name: 'LoL Championship Series',
        region: 'North America',
        lolesportsId: '98767991299243165',
        pandascoreId: 4198,
      },
      {
        code: 'LCK',
        image: 'lck',
        name: 'LoL Champions Korea',
        region: 'Korea',
        lolesportsId: '98767991310872058',
        pandascoreId: 293,
        delay: false,
      },
      {
        disabled: true,
        code: 'LPL',
        image: 'lpl',
        name: 'LoL Pro League',
        region: 'China',
        lolesportsId: '98767991314006698',
        pandascoreId: 294,
      },
    ],
    minor: [
      {
        code: 'CBLOL',
        image: 'cblol-brazil',
        name: 'Campeonato Brasileiro LoL',
        region: 'Brazil',
        lolesportsId: '98767991332355509',
        pandascoreId: 302,
      },
      {
        disabled: true,
        code: 'LCL',
        image: 'lcl',
        name: 'LoL Continental League',
        region: 'Russia',
        lolesportsId: '98767991355908944', // No data available on lolesports
        pandascoreId: 4004,
      },
      {
        code: 'LJL',
        image: 'ljl-japan',
        name: 'LoL Japan League',
        region: 'Japan',
        lolesportsId: '98767991349978712',
        pandascoreId: 2092,
      },
      {
        code: 'LLA',
        image: 'lla',
        name: 'Liga Latinoamérica',
        region: 'Latin America',
        lolesportsId: '101382741235120470',
        pandascoreId: 4199,
      },
      {
        disabled: true,
        code: 'PCS',
        image: 'pcs',
        name: 'Pacific Championship Series',
        region: 'Hong Kong, Macau, Taiwan',
        lolesportsId: '104366947889790212', // No data available on lolesports
        pandascoreId: 4288,
      },
      {
        disabled: true,
        code: 'VCS',
        image: 'vcs',
        name: 'Vietnam Championship Series',
        region: 'Vietnam',
        lolesportsId: '107213827295848783',
        pandascoreId: 4141,
      },
      /*{
        code: 'LCO',
        image: 'lco',
        name: 'LoL Circuit Oceania',
        region: 'Oceania',
        lolesportsId: '105709090213554609',
        pandascoreId: 4539,
      },*/
    ],
    academy: [
      {
        code: 'EUM',
        image: 'emea_masters',
        name: 'EMEA Masters',
        region: 'Europe',
        lolesportsId: '100695891328981122',
        pandascoreId: 4996,
      },
      /*{
        code: 'LCSA',
        image: 'lcs-academy',
        name: 'LCS Academy League',
        region: 'North America',
        lolesportsId: '99332500638116286',
        pandascoreId: 4228,
      },*/
      /*{
        disabled: true,
        code: 'TAL',
        image: 'tal',
        name: 'Turkish Academy League',
        region: 'Turkey',
        lolesportsId: '101097443346691685',
        pandascoreId: undefined,
      },
      {
        disabled: true,
        code: 'CK',
        image: 'ck',
        name: 'Challengers Korea',
        region: 'Korea',
        lolesportsId: undefined,
        pandascoreId: 4142,
      },*/
    ],
    erl: [
      {
        code: 'LFL',
        image: 'lfl',
        name: 'La League Française',
        region: 'France',
        lolesportsId: '105266103462388553',
        pandascoreId: 4292,
      },
      {
        code: 'PL',
        image: 'primeleague',
        name: 'Prime League',
        region: 'Germany-Austria-Switzerland',
        lolesportsId: '105266091639104326',
        pandascoreId: 4302,
      },
      {
        code: 'NLC',
        image: 'nlc',
        name: 'Northern League Championship',
        region: 'Northern Europe',
        lolesportsId: '105266098308571975',
        pandascoreId: 4411,
      },
      {
        code: 'UL',
        image: 'ultraliga',
        name: 'Ultraliga',
        region: 'Poland',
        lolesportsId: '105266088231437431',
        pandascoreId: 4300,
      },
      {
        code: 'SL',
        image: 'superliga',
        name: 'SuperLiga',
        region: 'Spain',
        lolesportsId: '105266074488398661',
        pandascoreId: 4213,
      },
      {
        code: 'TCL',
        image: 'turkiye-sampiyonluk-ligi',
        name: 'Turkish Championship League',
        region: 'Turkey',
        lolesportsId: '98767991343597634',
        pandascoreId: 1003,
      },
      /*{
        code: 'PG',
        image: 'pg_nationals',
        name: 'PG Nationals',
        region: 'Italy',
        lolesportsId: '105266094998946936',
        pandascoreId: 4405,
      },*/
      /*{
        code: 'ES',
        image: 'elite_series',
        name: 'Elite Series',
        region: 'Benelux',
        lolesportsId: '107407335299756365',
        pandascoreId: 4722,
      },*/
      /*{
        code: 'GLL',
        image: 'greek_legends',
        name: 'Greek Legends League',
        region: 'Greece',
        lolesportsId: '105266108767593290',
        pandascoreId: 4723,
      },*/
      /*{
        code: 'EBL',
        image: 'esports_balkan_league',
        name: 'Esports Balkan League',
        region: 'Balkan',
        lolesportsId: '105266111679554379',
        pandascoreId: 4426,
      },*/
      /*{
        code: 'HM',
        image: 'hitpoint_masters',
        name: 'Hitpoint Masters',
        region: 'Czech-Slovak',
        lolesportsId: '105266106309666619',
        pandascoreId: 4433
      },*/
      /*{
        code: 'LP',
        image: 'liga_portuguesa',
        name: 'Liga Portuguesa',
        region: 'Portugal',
        lolesportsId: '105266101075764040',
        pandascoreId: 4407,
      },*/
    ],
  },
};

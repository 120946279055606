import React from 'react';
import { Link as InternalLink } from 'react-router-dom';

const Link = ({ to, external, children, patreon, twitter, email, roadToWorlds, ...props }) => {
  let rel = 'noopener noreferrer';
  if (patreon) {
    to = 'https://www.patreon.com/piltoverpost';
    external = true;
    children = children || 'Patreon';
  }
  if (twitter) {
    to = 'https://twitter.com/piltoverpost';
    external = true;
    children = children || '@PiltoverPost';
  }
  if (email) {
    to = 'mailto:contact@piltoverpost.gg';
    external = true;
    children = children || 'contact@piltoverpost.gg';
  }
  if (roadToWorlds) {
    to = 'https://road-to-worlds.gg';
    external = true;
    children = children || 'Road to Worlds';
    rel = 'noopener';
  }

  if (external) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={to} target="_blank" rel={rel} {...props}>
        {children}
      </a>
    );
  }

  return <InternalLink to={to || ''} children={children} {...props} />;
};

export default Link;

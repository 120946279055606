import React from 'react'
import cn from 'classnames'

import { useDataDragon } from 'services'

import './Item.scss'

const Item = ({ id, className }) => {
  const { items, itemImageUrl } = useDataDragon()

  const item = items.data[id]

  return (
    <div data-tooltip={item?.name}>
      <div className={cn('item-image', className)}>
        <img src={itemImageUrl(id)} alt={item?.name || ''} />
      </div>
    </div>
  )
}

export default Item

import React from 'react'

import { formatTimeLasted } from 'helpers'

const Pause = ({ event }) => {
  return (
    <div className="event pause" data-timestamp={event.timestamp}>
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <small>
          {event.pause ? (
            <>Game has been paused</>
          ) : (
            <>Game has been resumed (pause lasted {formatTimeLasted(event.msElapsedInPause)})</>
          )}
        </small>
      </div>
    </div>
  )
}

export default Pause

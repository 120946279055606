import React from 'react'
import cn from 'classnames'

import { Champion, Item } from 'components'
import { Baron, Inhibitor, Kill, Tower, Gold, Creep } from 'icons'
import { useDataDragon } from 'services'
import { formatGold } from 'helpers'

import { useGameData } from '../../GameContext'

export const TeamStats = ({ team, color }) => (
  <div className={cn('team-stats', `${color}-team`)}>
    <div data-tooltip="Towers">
      <Tower />
      <div className="value">{team.towers}</div>
    </div>
    <div data-tooltip="Barons">
      <Baron />
      <div className="value">{team.barons}</div>
    </div>
    <div data-tooltip="Inhibitors">
      <Inhibitor />
      <div className="value">{team.inhibitors}</div>
    </div>
  </div>
)

export const TeamPlayers = ({ team, color, type }) => {
  const { metadata } = useGameData()

  const teamSize = metadata.participants.length / 2

  return (
    <div className={cn('team-players', `${color}-team`)}>
      {['top', 'jungle', 'mid', 'bot', 'support', 'extra'].slice(0, teamSize).map((role, i) => (
        <Player
          key={role}
          role={role}
          player={metadata.participants[i + (color === 'red' ? teamSize : 0)]}
          stats={team.participants[i]}
          type={type}
        />
      ))}
    </div>
  )
}

export const Player = ({ player, stats, type }) => {
  return (
    <div className={cn('player-stats', { alive: !!stats.alive })}>
      <span className="first-line">{player.name}</span>
      <div className="second-line">
        <Champion id={player.champId} dead={!stats.alive} />
        {type === 'stats' && <PlayerStats stats={stats} />}
        {type === 'items' && <PlayerItems items={stats.items} />}
      </div>
    </div>
  )
}

export const PlayerStats = ({ stats }) => {
  return (
    <div className="stats">
      <span className="kills" data-tooltip="KDA">
        {stats.kills}/{stats.deaths}/{stats.assists}
        <Kill />
      </span>
      <span data-tooltip="Gold">
        <span>{formatGold(stats.gold)}</span>
        <Gold />
      </span>
      <span data-tooltip="CS">
        {stats.creeps}
        <Creep />
      </span>
    </div>
  )
}

export const PlayerItems = ({ items: itemIds }) => {
  const { items: allItems } = useDataDragon()

  if (!allItems) {
    return <div className="items">Loading...</div>
  }

  const items = itemIds.map((id) => ({ id, ...allItems.data[id] }))

  const seventh = items.find((item) => {
    if (item.tags.includes('Trinket')) return true
    return [
      2138, // 'Elixir of Iron
      2139, // Elixir of Sorcery
      2140, // Elixir of Wrath
      3513, // Eye of the Herald
    ].includes(item.id)
  })

  const mainItems = seventh ? items.filter((item) => item.id !== seventh.id) : items

  return (
    <div className="items">
      <div className="main-items">
        {[...new Array(6)].map((x, i) => {
          if (mainItems[i])
            return <Item key={`${mainItems[i].id}-${i}`} id={mainItems[i].id} className="item" />
          return <div key={`empty-${i}`} className="item empty" />
        })}
      </div>
      {seventh ? <Item id={seventh.id} className="item" /> : <div className="item empty" />}
    </div>
  )
}

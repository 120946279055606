import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { formatGold, formatTime, timestampToSeconds } from 'helpers'
import { Baron, Cloud, Elder, Infernal, Mountain, Ocean, Hextech, Chemtech } from 'icons'

import './GoldGraph.scss'

const GoldGraphDebouncer = ({ data: rawData, events }) => {
  const [{ data }, setState] = useState({})

  const debouncedSetData = useMemo(
    () =>
      debounce(
        (data) => {
          // Add in-between points when data switch from positive to negative
          const reducedData = data.reduce((acc, next) => {
            if (!acc.length) return [next]

            const previous = acc[acc.length - 1]

            if (previous.y !== 0 && next.y !== 0 && Math.sign(previous.y) !== Math.sign(next.y)) {
              const inBetweenX =
                (Math.abs(previous.y) / (Math.abs(previous.y) + Math.abs(next.y))) *
                  (next.x - previous.x) +
                previous.x
              const inBetween = {
                x: Math.round(inBetweenX),
                y: 0,
              }
              return acc.concat([inBetween, next])
            }

            return acc.concat(next)
          }, [])

          setState({ data: reducedData })
        },
        5000,
        { leading: true, maxWait: 5000, trailing: true }
      ),
    [setState]
  )

  useEffect(() => {
    debouncedSetData(rawData)
  }, [rawData, debouncedSetData])

  if (!data || data.length === 0) return null

  return <GoldGraph data={data} events={events} />
}

const GoldGraph = ({ data, events }) => {
  const wrapperRef = useRef()
  const svgRef = useRef()

  const [width, setWidth] = useState(Math.min(window.innerWidth, 800))

  useEffect(() => {
    if (wrapperRef.current) {
      const onResize = () => setWidth(wrapperRef.current.offsetWidth)

      // First call
      onResize()

      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }
  }, [wrapperRef, setWidth])

  // 0. Dimension of the graphs
  const size = useMemo(() => {
    const size = {
      outer: {
        width,
        height: Math.max(275, width / 3),
      },
      margin: {
        left: width <= 425 ? 35 : 40,
        right: width <= 425 ? 5 : 40,
        top: 40,
        bottom: 45,
      },
    }
    size.inner = {
      width: size.outer.width - size.margin.left - size.margin.right,
      height: size.outer.height - size.margin.top - size.margin.bottom,
    }
    return size
  }, [width])

  // 1. Ranges of the data
  const MAX_X = Math.max(...data.map((d) => d.x))
  const MIN_Y_REAL = Math.min(...data.map((d) => d.y))
  const MIN_Y = Math.floor(MIN_Y_REAL / 100 - 1) * 100
  const MAX_Y_REAL = Math.max(...data.map((d) => d.y))
  const MAX_Y = Math.ceil(MAX_Y_REAL / 100 + 1) * 100

  const BASE_Y = MAX_Y / (MAX_Y - MIN_Y)
  const x = (val) => (val / MAX_X) * size.inner.width
  const y = (val) => (BASE_Y - val / (MAX_Y - MIN_Y)) * size.inner.height
  const invert_x = useCallback((val) => (val / size.inner.width) * MAX_X, [size.inner.width, MAX_X])
  const invert_y = (val) => BASE_Y - (val / size.inner.height) * (MAX_Y - MIN_Y) + MAX_Y

  // 2. Vertical axis
  const verticalAxis = (
    <path
      className="axis vertical"
      d={`M${size.margin.left - 0.5} ${size.margin.top - 0.5}
          L${size.margin.left - 0.5} ${size.margin.top + size.inner.height + 1}`}
    />
  )

  // 3. Horizontal axis
  const horizontalBaseAxis = (
    <path
      className="axis base horizontal"
      d={`M${size.margin.left} ${size.margin.top + BASE_Y * size.inner.height}
          L${size.margin.left + size.inner.width + 5} ${
        size.margin.top + BASE_Y * size.inner.height
      }`}
    />
  )
  const horizontalAxis = (
    <path
      className="axis horizontal"
      d={`M${size.margin.left} ${size.margin.top + size.inner.height + 0.5}
          L${size.margin.left + size.inner.width} ${size.margin.top + size.inner.height + 0.5}`}
    />
  )

  // 4. Vertical ticks
  const verticalTicks = (
    <>
      <path
        className="tick vertical"
        d={`M${size.margin.left} ${size.margin.top} L${size.margin.left - 3} ${size.margin.top}`}
      />
      <text
        className="tick vertical"
        textAnchor="end"
        alignmentBaseline="hanging"
        x={size.margin.left - 10}
        y={size.margin.top}
      >
        {formatGold(MAX_Y, false, false)}
      </text>
      <text
        className="tick vertical"
        textAnchor="end"
        alignmentBaseline="middle"
        x={size.margin.left - 7}
        y={size.margin.top + BASE_Y * size.inner.height}
      >
        0
      </text>
      <text
        className="tick vertical"
        textAnchor="end"
        alignmentBaseline="baseline"
        x={size.margin.left - 10}
        y={size.margin.top + size.inner.height}
      >
        {formatGold(Math.abs(MIN_Y), false, false)}
      </text>
    </>
  )

  // 5. Horizontal ticks
  const minuteElapsed = MAX_X / 1000 / 60
  const horizontalTicksInterval =
    minuteElapsed < 5 ? 1 : minuteElapsed < 10 ? 2 : minuteElapsed < 20 ? 5 : 10
  const horizontalTicksCount = Math.floor(MAX_X / 1000 / 60 / horizontalTicksInterval)
  const horizontalTicks = [...new Array(horizontalTicksCount + 1)].map((_, i) => (
    <g key={i}>
      <path
        className="horizontal tick"
        d={`M${size.margin.left + x(i * 60 * horizontalTicksInterval * 1000) - 0.5} ${
          size.margin.top + size.inner.height
        }
          L${size.margin.left + x(i * 60 * horizontalTicksInterval * 1000) - 0.5} ${
          size.margin.top + size.inner.height + 3.5
        }`}
      />
      <path
        className="horizontal tick full"
        d={`M${size.margin.left + x(i * 60 * horizontalTicksInterval * 1000) - 0.5} ${
          size.margin.top + size.inner.height
        }
          L${size.margin.left + x(i * 60 * horizontalTicksInterval * 1000) - 0.5} ${
          size.margin.top
        }`}
      />
      <text
        className="horizontal tick"
        textAnchor="middle"
        alignmentBaseline="hanging"
        x={size.margin.left + x(i * 60 * horizontalTicksInterval * 1000) - 0.5}
        y={size.margin.top + size.inner.height + 8}
      >
        {i * horizontalTicksInterval}
      </text>
    </g>
  ))

  // 6. Positive line
  const positiveLine = (
    <path
      className="line positive"
      d={`M${size.margin.left + x(data[0].x)} ${size.margin.top + y(data[0].y)}
          ${data
            .slice(1)
            .map((d) => {
              return `L${size.margin.left + x(d.x)} ${size.margin.top + y(Math.max(0, d.y))}`
            })
            .join(' ')}`}
    />
  )

  // 7. Negative line
  const negativeLine = (
    <path
      className="line negative"
      d={`M${size.margin.left + x(data[0].x)} ${size.margin.top + y(data[0].y)}
          ${data
            .slice(1)
            .map((d) => {
              return `L${size.margin.left + x(d.x)} ${size.margin.top + y(Math.min(0, d.y))}`
            })
            .join(' ')}`}
    />
  )

  // 8. Positive area
  const positiveArea = (
    <path
      className="area positive"
      d={`M${size.margin.left + x(data[0].x)} ${size.margin.top + y(data[0].y)}
          ${data
            .slice(1)
            .map((d) => {
              return `L${size.margin.left + x(d.x)} ${size.margin.top + y(Math.max(0, d.y))}`
            })
            .join(' ')}
          L${size.margin.left + x(data[data.length - 1].x)} ${size.margin.top + y(0)}
          L${size.margin.left + x(0)} ${size.margin.top + y(0)}`}
    />
  )

  // 9. Negative area
  const negativeArea = (
    <path
      className="area negative"
      d={`M${size.margin.left + x(data[0].x)} ${size.margin.top + y(data[0].y)}
          ${data
            .slice(1)
            .map((d) => {
              return `L${size.margin.left + x(d.x)} ${size.margin.top + y(Math.min(0, d.y))}`
            })
            .join(' ')}
          L${size.margin.left + x(data[data.length - 1].x)} ${size.margin.top + y(0)}
          L${size.margin.left + x(0)} ${size.margin.top + y(0)}`}
    />
  )

  // 10. Mouseover
  const [mousePosition, setMousePosition] = useState(null)
  const onMouseMove = useMemo(
    () => (mouseX, mouseY) => {
      if (mouseX >= size.margin.left && mouseX <= size.margin.left + size.inner.width) {
        if (mouseY >= size.margin.top && mouseY <= size.margin.top + size.inner.height) {
          setMousePosition({
            x: mouseX - size.margin.left,
            y: mouseY - size.margin.top,
          })
          return
        }
      }
      setMousePosition(null)
    },
    [setMousePosition, size]
  )

  let slider
  if (mousePosition) {
    const before = data.filter(({ x: px }) => x(px) <= mousePosition.x).pop() || data[0]
    const after =
      data.filter(({ x: px }) => x(px) >= mousePosition.x).shift() || data[data.length - 1]

    let inBetweenY
    if (before.x === after.x) {
      inBetweenY = y(after.y)
    } else {
      inBetweenY =
        ((mousePosition.x - x(before.x)) / (x(after.x) - x(before.x))) *
          (y(after.y) - y(before.y)) +
          y(before.y) || y(0)
    }

    slider = (
      <>
        <path
          className="slider vertical"
          d={`M${size.margin.left + mousePosition.x} ${size.margin.top}
              L${size.margin.left + mousePosition.x} ${size.margin.top + size.inner.height + 1}`}
        />
        <path
          className="slider horizontal"
          d={`M${size.margin.left} ${size.margin.top + inBetweenY}
              L${size.margin.left + mousePosition.x} ${size.margin.top + inBetweenY}`}
        />
        <text
          className="slider info"
          textAnchor="middle"
          alignmentBaseline="baseline"
          x={size.margin.left + mousePosition.x}
          y={size.margin.top - 8}
        >
          {formatGold(Math.round(invert_y(inBetweenY)), false, false)} @{' '}
          {formatTime(Math.round(invert_x(mousePosition.x) / 1000))}
        </text>
      </>
    )
  }
  const onGraphClick = useCallback(
    (mousePosition) => {
      if (!mousePosition || events.length === 0) return

      const ms = invert_x(mousePosition.x)

      const event =
        events
          .filter((evt) => !['minute'].includes(evt.type))
          .filter((evt) => timestampToSeconds(evt.timestamp) * 1000 > ms)
          .shift() || events[events.length - 1]

      console.log(event)

      const eventElement = document.querySelector(`.event[data-timestamp='${event.timestamp}'`)

      if (eventElement) {
        eventElement.scrollIntoView()
        document.querySelector('html').scrollTop -= 45
      }
    },
    [events, invert_x]
  )
  const innerArea = (
    <rect
      className="inner area"
      onClick={() => onGraphClick(mousePosition)}
      x={size.margin.left}
      y={size.margin.top}
      width={size.inner.width}
      height={size.inner.height}
    />
  )

  // 11. Events
  const eventsLines = (
    <g>
      {events
        .filter((evt) => ['baron', 'dragon'].includes(evt.type))
        .map((evt) => {
          const Icon =
            evt.type === 'dragon'
              ? {
                  cloud: Cloud,
                  infernal: Infernal,
                  mountain: Mountain,
                  ocean: Ocean,
                  elder: Elder,
                  hextech: Hextech,
                  chemtech: Chemtech,
                }[evt.dragon]
              : Baron

          const evtMs = timestampToSeconds(evt.timestamp) * 1000
          const evtPoint = data.filter(({ x }) => x <= evtMs).pop()

          const scale = {
            dragon: 1.2,
            baron: 0.9,
          }[evt.type]

          const lineOrigin = y(evtPoint.y)
          let lineDestination
          let iconPosition

          const isPositive = Math.sign(evtPoint.y) >= 0

          const minLineHeight = 30

          if (evt.team === 'blue') {
            if (isPositive) {
              lineDestination = y(evtPoint.y) - minLineHeight
            } else {
              lineDestination = y(0) - minLineHeight
            }
            iconPosition = lineDestination - 3
          } else {
            if (isPositive) {
              lineDestination = y(0) + minLineHeight
            } else {
              lineDestination = y(evtPoint.y) + minLineHeight
            }
            iconPosition = lineDestination + 23
          }

          return (
            <g key={`${evt.timestamp}-${evt.type}`}>
              <g
                className={`icon ${evt.type}`}
                transform={`translate(${size.margin.left + x(evtPoint.x) - 11}, ${
                  iconPosition + 20
                }), scale(${scale})`}
              >
                <Icon />
              </g>
              <path
                className={`pointer ${evt.team}`}
                d={`M${size.margin.left + x(evtPoint.x)} ${size.margin.top + lineOrigin}
                    L${size.margin.left + x(evtPoint.x)} ${size.margin.top + lineDestination}`}
              />
            </g>
          )
        })}
    </g>
  )

  return (
    <div className="gold-graph" ref={wrapperRef}>
      <svg
        ref={svgRef}
        width={size.outer.width}
        height={size.outer.height}
        onMouseMove={(evt) => {
          const rect = svgRef.current.getBoundingClientRect()
          onMouseMove(Math.round(evt.clientX - rect.left), Math.round(evt.clientY - rect.top))
        }}
        onMouseLeave={() => setMousePosition(null)}
      >
        {verticalAxis}
        {horizontalAxis}
        {verticalTicks}
        {horizontalTicks}
        <g>
          {positiveLine}
          {negativeLine}
          {positiveArea}
          {negativeArea}
        </g>
        {eventsLines}
        {horizontalBaseAxis}
        {slider}
        {innerArea}
      </svg>
    </div>
  )
}

export default GoldGraphDebouncer

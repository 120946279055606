import React from 'react'
import cn from 'classnames'

import { eventColor, nth } from 'helpers'
import { Baron as Icon } from 'icons'

const Baron = ({ event, blueTeam, redTeam, metadata }) => {
  const { name } = { blueTeam, redTeam }[event.team + 'Team']

  return (
    <div
      className={cn('event', 'baron', eventColor(event, metadata))}
      data-timestamp={event.timestamp}
    >
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <div className="announcement">
          {name} has slain {event.count > 1 ? `their ${nth(event.count)}` : 'the'} Baron Nashor
        </div>
        <Icon />
      </div>
    </div>
  )
}

export default Baron

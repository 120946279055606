import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Link, Filter } from 'components';
import { Home } from 'icons';

import './Header.scss';

const Header = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <header className="is-full">
          <div className="title-wrapper">
            <h1 className="title" key="title" data-tooltip="HELLOOO" data-tooltip-position="bottom">
              The Piltover Post
            </h1>
            <div className="infobox">
              {/*<p>
                <b>FREE</b> - Receive weekly results and schedules in your inbox by subscribing to
                the{' '}
                <Link to="http://eepurl.com/hdJI5T" external>
                  Newsletter
                </Link>
                !
              </p>*/}
              {/*<p style={{ textAlign: 'center' }}>
                Do you enjoy using The Piltover Post?
                <br />
                Then please consider
                <br />
                <b>
                  supporting us on <Link patreon />
                </b>
                !
              </p>*/}
              <p style={{ textAlign: 'center' }}>
                <b>NEW</b> - <Link roadToWorlds />
                <br />
                The probability that your LEC team
                <br />
                reaches Worlds.
              </p>
            </div>
          </div>
          <div className="subtitle">
            <div className="container">
              <span />
              <span className="subtitle-sentence">
                Read live LoL esports games - {new Date().toDateString()}
              </span>
              <div className="settings">
                <Filter />
              </div>
            </div>
          </div>
        </header>
      </Route>
      <Route>
        <header className="is-slim">
          <div className="title-wrapper">
            <div className="go-home">
              <Link to="/" data-tooltip="Home" data-tooltip-direction="right">
                <Home />
              </Link>
            </div>
            <h1 className="title" key="title">
              <Link to="/">The Piltover Post</Link>
            </h1>
            <span />
            {/*<a href="#settings" className="settings">
              <span role="img" aria-label="Settings">
                ⚙️
              </span>
            </a>*/}
          </div>
        </header>
      </Route>
    </Switch>
  );
};

export default Header;

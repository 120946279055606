import React from 'react'

import { formatTimeLasted } from 'helpers'

const Chronobreak = ({ event }) => {
  return (
    <div className="event chronobreak" data-timestamp={event.timestamp}>
      <div className="timestamp">{event.timestamp}</div>
      <div className="content">
        <small>
          A Chronobreak as been used to rewind the game to an earlier state (Rewinded{' '}
          {formatTimeLasted(event.offset, true)} earlier)
        </small>
      </div>
    </div>
  )
}

export default Chronobreak

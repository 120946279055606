import React from 'react'

import { Articles, ErrorBoundary, Link } from 'components'

import './News.scss'

const News = () => {
  return (
    <div className="news-wrapper">
      <div className="news-header">
        <h2>🗞 Latest articles</h2>
        <div className="see-all">
          <Link to="/news/global">See more</Link>
        </div>
      </div>

      <Articles league="global" max={4} />
    </div>
  )
}

export default (props) => {
  return (
    <ErrorBoundary>
      <News {...props} />
    </ErrorBoundary>
  )
}
